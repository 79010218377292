<template>
    <v-layout fill-height>
        <v-col wrap justify-center>
            <v-flex md12>
                <material-card title="Edit Product Info"
                               text="Show and edit product information">
                    <w-edit-product :auth_type="2" :product_id="$route.params.product_id"></w-edit-product>
                </material-card>
            </v-flex>
        </v-col>
    </v-layout>
</template>
<style lang="scss">

</style>

<script>

    export default {
        components: {},
        data() {
            return {
                product_id: 0
            }
        },
        created() {
            // console.log(this.)
            // this.product_id  = this.$route.params.product_id || 0;
        },

    }
</script>
